import api from "../../api";
import {typedQuery} from "../typing";

export const userLoginMethod = typedQuery(["userLoginMethod"], async () => {
  return await api.userLoginMethod.get();
});

export const userLoginMethods = typedQuery(["userLoginMethods"], async () => {
  return await api.userLoginMethod.list();
});
