import React from "react";
import {RouterProvider} from "react-router-dom";

import {router} from "./router";
import OauthProviders from "./oauth/Providers";
import {QueryClientProvider} from "@tanstack/react-query";
import {queryClient} from "./state";
import ErrorRoot from "./Error";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import RootTheme from "./theme/RootTheme";
import {AppUpdatedMessage} from "./utils/import/AppUpdatedMessage";
import {ModalProvider} from "./components/modals/ModalProvider";
import {deleteModal} from "./modals";
import {provideModal} from "./components/modals";
import {DeleteModal} from "./components/DeleteModalButton";

export default function App() {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <RootTheme>
          <ErrorRoot>
            <ModalProvider
              modals={[
                provideModal(deleteModal, modalProps => {
                  return <DeleteModal {...modalProps} />;
                }),
              ]}
            >
              <OauthProviders>
                <AppUpdatedMessage />
                <RouterProvider router={router!} future={{v7_startTransition: true}} />
              </OauthProviders>
            </ModalProvider>
          </ErrorRoot>
        </RootTheme>
      </QueryClientProvider>
    </React.StrictMode>
  );
}
