import * as auth from "./auth";
import * as externalAuthorizations from "./externalAuthorizations.ts";
import * as config from "./config";
import * as users from "./users";
import * as roles from "./roles.ts";
import * as userLoginMethod from "./userLoginMethod.ts";
import * as files from "./files";
import * as activities from "./activities";
import * as supportEmails from "./supportEmails";
import * as backgroundTasks from "./backgroundTasks";
import * as notifications from "./notifications/inAppNotifications.ts";
import * as notificationChannels from "./notifications/notificationChannels.ts";
import * as notificationPreferences from "./notifications/notificationPreferences.ts";
import * as accessTokens from "./accessTokens.ts";
import * as teams from "./teams.ts";
import * as owners from "./owners.ts";
import * as reportedErrors from "./reportedErrors.ts";
import * as reminderSchedules from "./reminderSchedules.ts";
import * as scim from "./scim";
import * as verifiedDomains from "./verifiedDomains";
import * as loginMethod from "./loginMethod";
import vendorToolkit from "./vendorToolkit";
import tprm from "./tprm";

export class HTTPError extends Error {
  response: Response;
  constructor(response: Response) {
    super(`HTTP Error: ${response.status} ${response.statusText}`);
    this.response = response;
  }
  // Don't report 403 or 404 errors
  get _isNominal() {
    return this.response.status == 403 || this.response.status == 404;
  }
}

const api = {
  users,
  roles,
  vendorToolkit,
  tprm,
  auth,
  externalAuthorizations,
  config,
  userLoginMethod,
  loginMethod,
  files,
  activities,
  supportEmails,
  backgroundTasks,
  notifications,
  notificationChannels,
  notificationPreferences,
  accessTokens,
  teams,
  owners,
  reportedErrors,
  reminderSchedules,
  scim,
  verifiedDomains,
};

export default api;
