// eslint-disable-next-line no-restricted-imports
import {Modal, ModalContent, ModalOverlay, ModalProps as ChakraModalProps} from "@chakra-ui/react";
import {ComponentType, Suspense} from "react";
import Loading from "../components/Loading";
import {useModalDiagnostics} from "../utils/diagnostics";

export type ModalControlProps = {
  isOpen: boolean;
  onClose: () => void;
  onCloseComplete?: () => void;
};

type ModalProps = Omit<ChakraModalProps, keyof ModalControlProps | "children">;

export function withModal<P extends Partial<ModalControlProps>>(
  WrappedComponent: ComponentType<P>,
  modalProps?: ModalProps,
): ComponentType<ModalControlProps & P> {
  return (props: ModalControlProps & P) => {
    useModalDiagnostics(props.isOpen);

    return (
      <Modal isOpen={props.isOpen} onClose={props.onClose} onCloseComplete={props.onCloseComplete} {...modalProps}>
        <ModalOverlay />
        <ModalContent>
          <Suspense fallback={<Loading m={8} />}>
            <WrappedComponent {...props} />
          </Suspense>
        </ModalContent>
      </Modal>
    );
  };
}
