import {FileId, Source, SourceId, SourceUsageType} from "../../Types";
import {invalidateQueries, q} from "../../state"; // Updated import to include q
import jsonApi from "../jsonApi";

export async function get(sourceId: SourceId): Promise<Source> {
  return jsonApi.get<Source>(`/tprm/sources/${sourceId}`);
}

export async function delete_(sourceId: SourceId): Promise<"ok"> {
  const res = await jsonApi.delete_<"ok">(`/tprm/sources/${sourceId}`);
  await invalidateQueries([q.tprm.dataRoomSources.filter()]);
  return res;
}

export async function update(sourceId: SourceId, sourceUsageType: SourceUsageType, update: boolean): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(`/tprm/sources/${sourceId}/usage/${sourceUsageType}`, update);
  await invalidateQueries([q.tprm.source.filter(sourceId), q.tprm.dataRoomSources.filter()]);
  return res;
}

export async function updateDocumentFile(sourceId: SourceId, fileId: FileId): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(`/tprm/sources/${sourceId}/file`, fileId);
  await invalidateQueries([q.tprm.source.filter(sourceId), q.tprm.dataRoomSources.filter()]);
  return res;
}

export async function updateNonAuthoritativeMarkdown(sourceId: SourceId, markdown: string) {
  const res = await jsonApi.post<"ok">(`/tprm/sources/${sourceId}/non_authoritative_markdown`, markdown);
  await invalidateQueries([q.tprm.source.filter(sourceId), q.tprm.dataRoomSources.filter()]);
  return res;
}
