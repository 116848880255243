import {LoginMethod, ValidateLoginMethod, LoginMethodId} from "../Types";
import {invalidateQueries, q} from "../state";
import jsonApi from "./jsonApi";

export async function list() {
  return await jsonApi.get<LoginMethod[]>(`/login_methods`);
}

export async function create(body: ValidateLoginMethod) {
  const res = await jsonApi.post<LoginMethod[]>(`/login_methods`, body);
  await invalidateQueries([q.loginMethods.filter()]);
  return res;
}

export async function delete_(loginMethodId: LoginMethodId) {
  const res = await jsonApi.delete_<"ok">(`/login_methods/${loginMethodId}`);
  await invalidateQueries([q.loginMethods.filter()]);
  return res;
}

export async function update(loginMethodId: LoginMethodId, body: ValidateLoginMethod) {
  const res = await jsonApi.post<"ok">(`/login_methods/${loginMethodId}`, body);
  await invalidateQueries([q.loginMethods.filter()]);
  return res;
}
