import {
  AddLoginMethod,
  LoginMethodId,
  LoginMethodMin,
  LoginRedirectRequest,
  RequestLoginReset,
  ResetLoginInfo,
  UserLoginMethod,
} from "../Types";
import {invalidateQueries, q} from "../state";
import jsonApi, {DEFAULT_API_OPTIONS} from "./jsonApi";

export async function get() {
  return await jsonApi.get<LoginMethodMin>("/login/method");
}

export async function set(LoginMethodId: LoginMethodId) {
  const res = await jsonApi.post<"ok">("/login/method", LoginMethodId);
  await invalidateQueries([q.registeredUsers.filter(), q.user.filter(), q.whoAmI.filter(), q.userLoginMethod.filter()]);
  return res;
}

export async function validateResetToken(token: string) {
  return await jsonApi.get<ResetLoginInfo>("/login/reset", {
    ...DEFAULT_API_OPTIONS,
    handleAuthErrors: false,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function reset(token: string, loginMethodId: LoginMethodId, payload: AddLoginMethod) {
  return await jsonApi.post<"ok">(`/login/reset/${loginMethodId}`, payload, {
    ...DEFAULT_API_OPTIONS,
    handleAuthErrors: false,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function requestReset(requestLoginReset: RequestLoginReset) {
  return await jsonApi.post<"ok">("/login/request_reset", requestLoginReset);
}

export async function upsert(loginMethodId: LoginMethodId, payload: AddLoginMethod) {
  const res = await jsonApi.put<"ok">(`/login/methods/${loginMethodId}`, payload);
  await invalidateQueries([q.userLoginMethod.filter()]);
  return res;
}

export async function delete_(loginMethodId: LoginMethodId) {
  const res = await jsonApi.delete_<"ok">(`/login/methods/${loginMethodId}`);
  await invalidateQueries([q.userLoginMethod.filter()]);
  return res;
}

export async function requestRedirectUrl(loginMethodId: LoginMethodId, req: LoginRedirectRequest) {
  const res = await jsonApi.post<string>(`/login/methods/${loginMethodId}/redirect`, req);
  return res;
}

export async function list() {
  return await jsonApi.get<UserLoginMethod[]>(`/login/methods`);
}
