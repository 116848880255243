import {VerifiedDomain, CreateVerifiedDomain, VerifiedDomainId} from "../Types";
import {invalidateQueries, q} from "../state";
import jsonApi from "./jsonApi";

export async function list() {
  return await jsonApi.get<VerifiedDomain[]>("/verified_domains");
}

export async function add(create: CreateVerifiedDomain) {
  const res = await jsonApi.post<VerifiedDomainId>(`/verified_domains`, create);
  await invalidateQueries([q.verifiedDomains.filter()]);
  return res;
}

export async function verify(verifiedDomainId: VerifiedDomainId) {
  const res = await jsonApi.post<"ok">(`/verified_domains/${verifiedDomainId}/verify`);
  await invalidateQueries([q.verifiedDomains.filter()]);
  return res;
}

export async function updateEnableSelfEnrollment(verifiedDomainId: VerifiedDomainId, enabled: boolean) {
  const res = await jsonApi.post<"ok">(`/verified_domains/${verifiedDomainId}/enable_self_enrollment`, enabled);
  await invalidateQueries([q.verifiedDomains.filter()]);
  return res;
}

export async function remove(verifiedDomainId: VerifiedDomainId) {
  const res = await jsonApi.delete_<"ok">(`/verified_domains/${verifiedDomainId}`);
  await invalidateQueries([q.verifiedDomains.filter()]);
  return res;
}
