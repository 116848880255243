import {ScimConfigList, ScimConfigId, CreateScimConfig} from "../Types";
import {invalidateQueries, q} from "../state";
import jsonApi from "./jsonApi";

export async function listConfigs() {
  return await jsonApi.get<ScimConfigList>("/scim_configs");
}

export async function createConfig(config: CreateScimConfig) {
  const res = await jsonApi.post<ScimConfigId>(`/scim_configs`, config);
  await invalidateQueries([q.scimConfigs.filter()]);
  return res;
}

export async function updateConfigName(configId: ScimConfigId, name: string) {
  const res = await jsonApi.post<"ok">(`/scim_configs/${configId}/name`, name);
  await invalidateQueries([q.scimConfigs.filter()]);
  return res;
}

export async function updateConfigSendInvitations(configId: ScimConfigId, sendInvitations: boolean) {
  const res = await jsonApi.post<"ok">(`/scim_configs/${configId}/send_invitations`, sendInvitations);
  await invalidateQueries([q.scimConfigs.filter()]);
  return res;
}

export async function regenerateConfigToken(configId: ScimConfigId) {
  const res = await jsonApi.post<"ok">(`/scim_configs/${configId}/auth_token`);
  await invalidateQueries([q.scimConfigs.filter()]);
  return res;
}

export async function deleteConfig(configId: ScimConfigId) {
  const res = await jsonApi.delete_<"ok">(`/scim_configs/${configId}`);
  await invalidateQueries([q.scimConfigs.filter()]);
  return res;
}
