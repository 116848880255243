import {
  Box,
  Button,
  ButtonProps,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Tooltip,
} from "@chakra-ui/react";
import {TrashIcon} from "@heroicons/react/24/solid";
import React from "react";
import {withModal} from "../state/withModal";
import {usePromiseState} from "../hooks/promiseState";
import {deleteModal} from "../modals";
import {useModalContext} from "./modals";

type DeleteModalProps = {
  delete_: () => Promise<unknown>;

  modalHeader: React.ReactNode;
  modalBody: React.ReactNode;
};

type DeleteModalButtonProps = {
  buttonProps?: Partial<ButtonProps>;
} & DeleteModalProps;

export const DeleteModalButton = ({buttonProps, ...props}: DeleteModalButtonProps) => {
  const {open} = useModalContext();
  return (
    <>
      <Button colorScheme="red" type="button" onClick={() => open(deleteModal, props)} {...buttonProps}>
        Delete
      </Button>
    </>
  );
};

type DeleteModalIconButtonProps = {
  tooltip?: string;
  buttonProps?: Partial<IconButtonProps>;
} & DeleteModalProps;

export const DeleteModalIconButton = ({buttonProps, tooltip, ...props}: DeleteModalIconButtonProps) => {
  const {open} = useModalContext();
  return (
    <>
      <Tooltip label={tooltip}>
        <IconButton
          aria-label="Delete"
          icon={<Icon as={TrashIcon} />}
          onClick={() => open(deleteModal, props)}
          bgColor="white"
          variant="outline"
          color="gray.500"
          {...buttonProps}
        />
      </Tooltip>
    </>
  );
};

export const DeleteModal = withModal(
  ({onClose, delete_, modalHeader, modalBody}: DeleteModalProps & {onClose: () => void}) => {
    const [deleting, doDelete] = usePromiseState(async () => {
      await delete_();
      onClose();
    }, [delete_, onClose]);
    return (
      <>
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="md">{modalBody}</ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              isDisabled={deleting.inProgress}
              onClick={doDelete}
              isLoading={deleting.inProgress}
            >
              Delete
            </Button>
          </HStack>
        </ModalFooter>
      </>
    );
  },
);

export const DeletedItem = ({children}: {children: React.ReactNode}) => {
  return (
    <Box
      bg="gray.50"
      fontWeight="500"
      fontSize="md"
      marginY="4"
      padding="4"
      borderRadius="md"
      borderColor="gray.200"
      borderWidth="1px"
    >
      {children}
    </Box>
  );
};
