import {ReactNode, useContext} from "react";
import {modalContext} from "./context";
import {ModalControlProps} from "../../state/withModal";

export function useModalContext() {
  return useContext(modalContext);
}

const modalProps = Symbol("modalProps");
export type ModalId<T> = symbol & {[modalProps]: T};

export type ModalRenderFunc<T = any> = (props: ModalControlProps & T) => ReactNode;
export type ProvidedModal<T = any> = [ModalId<T>, ModalRenderFunc];

export function declareModal<T = undefined>(): ModalId<T> {
  return Symbol() as ModalId<T>;
}

export function provideModal<T>(modalId: ModalId<T>, f: ModalRenderFunc<T>): ProvidedModal {
  return [modalId, f];
}
