import React, {useCallback, useContext, useMemo, useState} from "react";
import {OpenModal, modalContext} from "./context";
import {useEventCallback} from "usehooks-ts";
import {ModalId, ModalRenderFunc, ProvidedModal} from ".";
import {useSubState} from "../../hooks/subState";

type ModalProviderProps = {
  modals: ProvidedModal[];
  children?: React.ReactNode;
};

type ModalState = {
  isOpen: boolean;
  props: any;
};

type ModalStates = {[modalId: ModalId<any>]: ModalState};

export function ModalProvider({modals, children}: ModalProviderProps) {
  const parent = useContext(modalContext);

  const [modalStates, setModalStates] = useState<ModalStates>(() =>
    Object.fromEntries(modals.map(([id]) => [id, {isOpen: false, props: {}} satisfies ModalState])),
  );

  const open: OpenModal = useEventCallback(<T,>(modalId: ModalId<T>, modalProps: T = {} as T) => {
    if (Object.hasOwn(modalStates, modalId)) {
      setModalStates(prev => ({
        ...prev,
        [modalId]: {
          isOpen: true,
          props: modalProps,
        } satisfies ModalState,
      }));
    } else {
      parent.open(modalId, modalProps);
    }
  });

  const value = useMemo(() => ({open}), [open]);

  return (
    <>
      <modalContext.Provider value={value}>{children}</modalContext.Provider>

      {modals.map(([modalId, renderFunc], idx) => (
        <RenderedModal
          key={idx}
          modalStates={modalStates}
          setModalStates={setModalStates}
          modalId={modalId}
          renderFunc={renderFunc}
        />
      ))}
    </>
  );
}

function RenderedModal<T>({
  modalId,
  renderFunc,
  modalStates,
  setModalStates,
}: {
  modalId: ModalId<T>;
  renderFunc: ModalRenderFunc<T>;
  modalStates: ModalStates;
  setModalStates: React.Dispatch<React.SetStateAction<ModalStates>>;
}) {
  const [modalState, setModalState] = useSubState(modalStates, setModalStates, s => s.property(modalId));

  const isOpen = modalState.isOpen;
  const onClose = useCallback(() => setModalState(s => ({...s, isOpen: false})), [setModalState]);
  const onCloseComplete = useCallback(() => setModalState(s => ({...s, props: {}})), [setModalState]);
  const props = modalState.props;

  return renderFunc({isOpen, onClose, onCloseComplete, ...props});
}
