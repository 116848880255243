import {
  FrameworkMin,
  FrameworkId,
  FrameworkVersionMin,
  CreateFrameworkVersion,
  CreateFramework,
  IObject,
  OwnerId,
} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function list(): Promise<FrameworkMin[]> {
  return jsonApi.get("/tprm/frameworks");
}

export async function get(frameworkId: FrameworkId): Promise<FrameworkMin> {
  return jsonApi.get(`/tprm/frameworks/${frameworkId}`);
}

export async function create(framework: CreateFramework): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/frameworks`, framework);
  await invalidateQueries([q.tprm.frameworks.filter()]);
  return res;
}

export async function delete_(frameworkId: FrameworkId): Promise<string> {
  const res = await jsonApi.delete_<"ok">(`/tprm/frameworks/${frameworkId}`);
  await invalidateQueries([q.tprm.frameworks.filter()]);
  await invalidateQueries([q.tprm.frameworkVersions.filter()]);
  return res;
}

export async function createVersion(frameworkId: FrameworkId, version: CreateFrameworkVersion): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/frameworks/${frameworkId}/versions`, version);
  await invalidateQueries([q.tprm.framework.filter(frameworkId)]);
  await invalidateQueries([q.tprm.frameworkVersions.filter()]);
  return res;
}

export async function createVersionFromPreset(frameworkId: FrameworkId, preset: IObject): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/frameworks/${frameworkId}/create_from_preset`, preset);
  await invalidateQueries([q.tprm.framework.filter(frameworkId)]);
  await invalidateQueries([q.tprm.frameworkVersions.filter()]);
  return res;
}

export async function listVersions(frameworkId: FrameworkId): Promise<FrameworkVersionMin[]> {
  return jsonApi.get(`/tprm/frameworks/${frameworkId}/versions`);
}

export async function updateName(frameworkId: FrameworkId, name: string): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/frameworks/${frameworkId}/name`, name);
  await invalidateQueries([q.tprm.framework.filter(frameworkId)]);
  return res;
}

export async function createFromPreset(preset: IObject): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/frameworks/create_from_preset`, preset);
  await invalidateQueries([q.tprm.frameworks.filter()]);
  return res;
}

export async function assign(frameworkId: FrameworkId, owner: OwnerId | null): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(`/tprm/frameworks/${frameworkId}/owner`, owner);
  await invalidateQueries([q.tprm.frameworks.filter(), q.tprm.framework.filter(), q.tprm.frameworkVersions.filter()]);
  return res;
}
